<template>
  <div class="header">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <nav-menu></nav-menu>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navigation from "./Navigation";
export default {
  components: {
    "nav-menu": Navigation,
  },
};
</script>

<style></style>
