import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "خرید تتر ارزان و مطمئن",
      metaTags: [
        {
          name: "description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
        {
          property: "og:description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
      ],
    },
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "درباره ما",
      metaTags: [
        {
          name: "description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
        {
          property: "og:description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "تماس با ما",
      metaTags: [
        {
          name: "description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
        {
          property: "og:description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "ورود به سیستم",
      metaTags: [
        {
          name: "description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
        {
          property: "og:description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
      ],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Auth/Login.vue"),
  },
  {
    path: "/signup",
    name: "Sign Up",
    meta: {
      title: "ثبت نام",
      metaTags: [
        {
          name: "description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
        {
          property: "og:description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
      ],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Auth/Signup.vue"),
  },
  {
    path: "/verify",
    name: "Verify OTP",
    meta: {
      title: "تایید شماره موبایل",
      metaTags: [
        {
          name: "description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
        {
          property: "og:description",
          content: "خرید تتر ارزان و سریع - uTether مرجع خرید تتر",
        },
      ],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Auth/VerifyOTP.vue"),
  },
];

const router = createRouter({
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title + " | uTether";
//   document.metaTags = to.metaTags;
//   next()
// });

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + " | uTether";
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title + " | uTether";
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
