<template>
  <div class="bottom section-padding">
    <div class="container">
      <div class="row">
        <div class="col-xl-3">
          <div class="bottom-logo">
            <img
              src="/images/Tether_Logo.svg"
              width="140px"
              alt=""
              class="pb-3"
            />

            <p>خرید تتر ارزان ، مطمئن و آسان</p>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="bottom-widget">
            <h4 class="widget-title">uTether</h4>
            <ul>
              <li><a href="/about">درباره ما</a></li>
              <li><a href="#">تماس با ما</a></li>
              <li><a href="#">همکاری</a></li>
              <li><a href="#">شرایط و قوانین</a></li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="bottom-widget">
            <h4 class="widget-title">پشتیبانی</h4>
            <ul>
              <li><a href="#">درخواست پشتیبانی</a></li>
              <li><a href="#">سوالات</a></li>
              <li><a href="#">بلاگ</a></li>
              <li><a href="#">ورود به پنل</a></li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="bottom-widget">
            <h4 class="widget-title">پنل کاربری</h4>
            <div class="row">
              <div class="col-xl-12">
                <ul>
                  <li><a href="#">ورود به پنل</a></li>
                  <li><a href="#">ثبت نام</a></li>
                  <li><a href="#">سوابق خرید</a></li>
                  <li><a href="#">پشتیبانی</a></li>
                </ul>
              </div>
              <!-- <div class="col-xl-6">
                <ul>
                  <li><a href="#">BTC to USDT</a></li>
                  <li><a href="#">LTC to BTC</a></li>
                  <li><a href="#">XMR to BTC</a></li>
                  <li><a href="#">ETC to DASH</a></li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-xl-6">
          <div class="copyright">
            <p>© تمامی حقوق برای <a href="/">یوتتر</a> محفوظ می باشد</p>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="footer-social">
            <ul>
              <li>
                <a href="#"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-linkedin"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-youtube"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style></style>
