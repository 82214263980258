<template>
  <div class="intro-form-exchange">
    <form
      @submit.prevent="submitOrder"
      action="/"
      method="get"
      name="orderForm"
      class="currency_validate"
    >
      <div class="form-group">
        <div class="d-flex justify-content-between mt-0 align-items-center">
          <label class="mb-3">تعداد ارز :</label>
          <h6 class="mb-3">
            <div class="mr-sm-2 coin_icon"></div>
            <i class="cc USDT"></i> Tether USDT
          </h6>
        </div>
        <div class="input-group">
          <input
            id="buyTetherFormQtyInput"
            type="number"
            class="form-control"
            v-bind:class="{ error: v$.qty.$errors.length }"
            v-model="state.qty"
          />
          <label v-if="v$.qty.$error" class="error" for="qty">
            {{ v$.qty.$errors[0].$message }}
          </label>
        </div>
      </div>
      <div class="text-left">
        <h4 class="mb-0">
          <label class="navy-text">مجموع :&nbsp;</label>
          <abbr id="cartSum">265،000</abbr> تومان
          <img width="16px" src="images/toman.svg" class="rls-logo" />
        </h4>
      </div>
    </form>
  </div>
  <button
    id="submitBuyTetherForm"
    type="submit"
    name="submit"
    class="btn btn-success btn-block bottom-border"
    @click="submitOrder"
  >
    ثبت سفارش
    <i class="la la-arrow-left"></i>
  </button>
</template>

<script>
import { reactive, computed } from "vue";
import useValidate from "@vuelidate/core";
import { required, minValue, maxValue, helpers } from "@vuelidate/validators";

export default {
  name: "BuyTetherForm",
  setup() {
    const state = reactive({
      qty: 100,
      currency: "usdt",
    });
    const rules = computed(() => {
      return {
        qty: {
          required: helpers.withMessage("لطفا تعداد را وارد نمایید", required),
          minValue: helpers.withMessage(
            "حداقل تعداد خرید 100 عدد میباشد",
            minValue(100)
          ),
          maxValue: helpers.withMessage(
            "حداکثر تعداد خرید 1,500 عدد میباشد",
            maxValue(1500)
          ),
        },
        currency: {
          required,
        },
      };
    });
    const v$ = useValidate(rules, state);
    return { state, v$ };
  },
  methods: {
    submitOrder: function (e) {
      e.preventDefault();
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        // if ANY fail validation
        alert("Form successfully submitted.");
      } else {
        document.querySelector("#buyTetherFormQtyInput").focus();
      }
    },
  },
};
</script>
