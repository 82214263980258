<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <main-header></main-header>
  <!-- NEW - put the InstallPrompt component on the page -->
  <install-prompt></install-prompt>
  <router-view />
  <main-footer></main-footer>
</template>

<script>
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import InstallPrompt from "@/components/InstallPrompt";
export default {
  components: {
    "main-header": Header,
    "main-footer": Footer,
    "install-prompt": InstallPrompt,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
}
</style>
