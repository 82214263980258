<template>
  <div class="navigation">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="/"
        ><img src="/images/Tether_Logo.svg" width="140px" alt=""
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <!-- <a class="nav-link" href="/">خانه </a> -->
            <router-link class="nav-link" to="/">صفحه اصلی</router-link>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="/about">درباره </a> -->
            <router-link class="nav-link" to="/about">ارز دیجیتال</router-link>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="/about">درباره </a> -->
            <router-link class="nav-link" to="/about"
              >شرایط و قوانین</router-link
            >
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="/about">درباره </a> -->
            <router-link class="nav-link" to="/about">درباره ما</router-link>
          </li>

          <li class="nav-item">
            <!-- <a class="nav-link" href="/about">درباره </a> -->
            <router-link class="nav-link" to="/contact">تماس با ما</router-link>
          </li>

          <li class="nav-item signin-btn">
            <router-link class="btn btn-primary" to="/login"
              >ورود به پنل</router-link
            >
            <!-- <a class="btn btn-primary" href="/login">ورود به پنل</a> -->
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
export default {};
</script>

<style></style>
