<template>
  <div
    class="alert alert-dismissible alert-info install-box"
    v-if="showInstallBanner"
  >
    مایل به
    <a href="#" @click.prevent="install">نصب برنامه هستید ؟</a>
    <button type="button" class="close" data-dismiss="alert">&times;</button>
  </div>
</template>

<script>
// src/components/InstallPrompt.vue
let installEvent;
export default {
  name: "installPrompt",
  data() {
    return {
      showInstallBanner: false,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      installEvent = e;
      this.showInstallBanner = true;
    });
  },
  methods: {
    install() {
      this.showInstallBanner = false;
      installEvent.prompt();
      installEvent.userChoice.then(() => {
        installEvent = null;
      });
    },
  },
};
</script>
