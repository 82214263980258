<template>
  <div class="intro2" id="intro" data-scroll-index="0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-12">
          <div class="intro-content text-center">
            <h1>خرید تتر ارزان</h1>
            <p>ارزان ترین قیمت تتر</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="exchange-form">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-7 col-lg-7">
          <buy-tether-form></buy-tether-form>
        </div>
      </div>
    </div>
  </div>
  <div class="trust section-padding">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4">
          <div class="trust-content">
            <span><i class="fa fa-shield"></i></span>
            <h4>امنیت</h4>
            <p>در امن ترین پلتفرم فروش تتر با خیالی آسوده خرید کنید</p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4">
          <div class="trust-content">
            <span><i class="fa fa-cubes"></i></span>
            <h4>سادگی</h4>
            <p>ساده ترین روش خرید تتر ، فقط با چند کلیک و چند ثانیه</p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4">
          <div class="trust-content">
            <span><i class="fa fa-life-ring"></i></span>
            <h4>قیمت</h4>
            <p>پایین ترین قیمت در بین تمامی فروشندگان</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="price-grid section-padding ltr">
    <div class="container">
      <div class="mb-5">
        <h2 class="text-center">قیمت لحظه‌ ای ارز‌های دیجیتال</h2>
        <p class="text-center">نمودار قیمت لحظه ای ارز های دیجیتال پر طرفدار</p>
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
          <div class="card">
            <div class="card-header">
              <p class="mb-0">24h</p>
              <div class="media">
                <div class="media-body">Bitcoin</div>
                <span><i class="cc BTC"></i></span>
              </div>
            </div>
            <div class="card-body" style="position: relative">
              <h3>USD 62,548.2254</h3>
              <span class="text-success">+2.05%</span>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
          <div class="card">
            <div class="card-header">
              <p class="mb-0">24h</p>
              <div class="media">
                <div class="media-body">Bitcoin</div>
                <span><i class="cc BTC"></i></span>
              </div>
            </div>
            <div class="card-body" style="position: relative">
              <h3>USD 62,548.2254</h3>
              <span class="text-success">+2.05%</span>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
          <div class="card">
            <div class="card-header">
              <p class="mb-0">24h</p>
              <div class="media">
                <div class="media-body">Bitcoin</div>
                <span><i class="cc BTC"></i></span>
              </div>
            </div>
            <div class="card-body" style="position: relative">
              <h3>USD 62,548.2254</h3>
              <span class="text-success">+2.05%</span>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
          <div class="card">
            <div class="card-header">
              <p class="mb-0">24h</p>
              <div class="media">
                <div class="media-body">Bitcoin</div>
                <span><i class="cc BTC"></i></span>
              </div>
            </div>
            <div class="card-body" style="position: relative">
              <h3>USD 62,548.2254</h3>
              <span class="text-success">+2.05%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="product-feature section-padding alt-bg">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-xl-5 col-lg-6">
          <div class="section-title">
            <h2 class="text-right">24 ساعت شبانه روز</h2>
            <p>
              در هر ساعت از شبانه روز ، در محیطی کاملا امن و با پایین ترین نرخ
              در بازار اقدام به تهیه تتر کنید
            </p>
          </div>
          <div class="product-feature-content">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="product-feature-text">
                  <h4>
                    <span><i class="fa fa-shield"></i></span> 2000+
                  </h4>
                  <p>مشتری فعال</p>
                </div>
                <div class="product-feature-text">
                  <h4>
                    <span><i class="fa fa-adjust"></i></span> 7/24
                  </h4>
                  <p>همیشه در دسترس</p>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div id="sparkline11"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div class="product-feature-box">
                <span class="bg-primary"><i class="la la-exchange"></i></span>
                <h4>خرید آسان</h4>
                <p>پایین ترین نرخ</p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div class="product-feature-box">
                <span class="bg-secondary"><i class="la la-trophy"></i></span>
                <h4>رتبه اول</h4>
                <p>فورش تخصصی تتر</p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div class="product-feature-box">
                <span class="bg-success"><i class="la la-user"></i></span>
                <h4>10،000</h4>
                <p>کاربران یو تتر</p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div class="product-feature-box">
                <span class="bg-info"><i class="la la-clock-o"></i></span>
                <h4>در کمتر از 1 دقیقه</h4>
                <p>فرایند خرید سریع</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="info section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6">
          <div class="info-content">
            <span><i class="la la-shield"></i></span>
            <h4>امنیت فراوان</h4>
          </div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6">
          <div class="info-content">
            <span><i class="la la-cubes"></i></span>
            <h4>امکانات کاربری</h4>
          </div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6">
          <div class="info-content">
            <span><i class="la la-clock-o"></i></span>
            <h4>فرآیند سریع</h4>
          </div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6">
          <div class="info-content">
            <span><i class="la la-exchange"></i></span>
            <h4>خرید و فروش</h4>
          </div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6">
          <div class="info-content">
            <span><i class="la la-support"></i></span>
            <h4>پشتیبانی قوی</h4>
          </div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6">
          <div class="info-content">
            <span><i class="la la-info"></i></span>
            <h4>بلاگ آموزش</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="contact-form section-padding" data-scroll-index="4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7">
          <div class="section-title text-center">
            <span>سوالی دارید ؟</span>
            <h2>به ما مستقیم پیام دهید</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-md-4 col-sm-12">
          <div class="info-list">
            <h4 class="mb-3">آدرس</h4>
            <ul>
              <li><i class="fa fa-map-marker"></i> ایران ، تهران</li>
              <li>
                <i class="fa fa-phone"></i>
                <a href="tel:02144696193">02144696193</a>
              </li>
              <li><i class="fa fa-envelope"></i> info@tether.ir</li>
            </ul>
          </div>
        </div>
        <div class="col-xl-8 col-md-8 col-sm-12">
          <form method="post" name="myform" class="contact_validate">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label> نام کامل </label>
                  <input
                    type="text"
                    class="form-control"
                    id="contactName"
                    placeholder="نام کامل"
                    name="firstname"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label> ایمیل </label>
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    placeholder="hello@domain.com"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <textarea
                    class="form-control p-3"
                    name="message"
                    rows="5"
                    placeholder="سوالات و یا درخواست پشتیبانی خود را اینجا برامون بنویسید"
                  ></textarea>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary px-4 py-2">
              ارسال پیام
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import useVuelidate from "@vuelidate/core";
// import { useValidate } from "@vuelidate/core";
import BuyTetherForm from "@/components/exchange/BuyTetherForm.vue";

export default {
  name: "Home",
  components: {
    "buy-tether-form": BuyTetherForm,
  },
  mounted() {
    // Toggle navigation on mobile
    if (window.innerWidth < 991) {
      var nav = document.querySelector(".navbar-collapse.collapse.show");
      if (nav != undefined) nav.classList.toggle("show");
    }
  },
};
</script>
